<template>
  <loader v-bind="{ loading }">
    <columns>
      <column>
        <page-heading :heading="location.name"></page-heading>
      </column>
      <column class="is-narrow">
        <tabs>
          <tab-link name="location-manager" :params="{ location: location.uuid }">Overview</tab-link>
          <tab-link name="location-equipment" :params="{ location: location.uuid }">Equipment</tab-link>
          <tab-link name="location-areas" :params="{ location: location.uuid }">Areas</tab-link>
          <tab-link v-if="!$root.isPassiveUser()" name="location-contacts" :params="{ location: location.uuid }">Contacts</tab-link>
          <tab-link name="location-work-orders" :params="{ location: location.uuid }">Work Orders</tab-link>
          <tab-link v-if="!$root.isPassiveUser()" name="location-preferences" :params="{ location: location.uuid }">Preferences</tab-link>
        </tabs>
      </column>
    </columns>
    <router-view />
  </loader>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  
  data: () => ({
    loading: true
  }),

  async beforeCreate() {
    await this.$store.dispatch('location/load', this.$route.params.location)
    this.loading = false
  },

  beforeDestroy() {
    this.$store.commit('location/clearLocation')
  },

  computed: mapGetters('location', [
    'location'
  ])

}
</script>